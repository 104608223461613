import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { companiesData } from './companiesData'
import { LandingCompaniesCard } from './LandingCompaniesCard'
import clsx from 'clsx'
import classes from './LandingCompanies.module.scss'

export const LandingCompanies = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.body)}>
        <h2 className={clsx('page__title', classes.title)}>
          The next generation of fast-growing SaaS companies are building with
          us
        </h2>

        <Slider />
      </div>
    </section>
  )
}

function Slider() {
  const { slidesToRender, carouselSettings } = useCarousel(companiesData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <LandingCompaniesCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
