import { StaticImageData } from 'next/image'
import ScenariosMobImage from './landing-cases-scenarios-mob.png'
import ScenariosDeskImage from './landing-cases-scenarios-desk.png'
import IntegrationsMobImage from './landing-cases-integrations-mob.png'
import IntegrationsDeskImage from './landing-cases-integrations-desk.png'
import ToolsMobImage from './landing-cases-tools-mob.png'
import ToolsDeskImage from './landing-cases-tools-desk.png'

export type CasesDataType = {
  title: string
  imageMob: StaticImageData
  imageDesk: StaticImageData
}

export const casesData: CasesDataType[] = [
  {
    title: 'One-click Integrations',
    imageMob: ScenariosMobImage,
    imageDesk: ScenariosDeskImage,
  },
  {
    title: 'Deep Native Integrations',
    imageMob: IntegrationsMobImage,
    imageDesk: IntegrationsDeskImage,
  },
  {
    title: 'In-app Workflow Builders',
    imageMob: ToolsMobImage,
    imageDesk: ToolsDeskImage,
  },
]
