import { TbArrowsHorizontal } from 'react-icons/tb'
import CodeImageMob1x from './landing-code-mob@1x.png'
import CodeImageMob2x from './landing-code-mob@2x.png'
import CodeImageDesk1x from './landing-code-desk@1x.png'
import CodeImageDesk2x from './landing-code-desk@2x.png'
import clsx from 'clsx'
import classes from './LandingCode.module.scss'

export const LandingCode = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <div className={clsx('flex-column', classes.content)}>
        <div className={clsx('flex-column', classes.heading)}>
          <h2 className={clsx('page__title', classes.title)}>Code</h2>
          <TbArrowsHorizontal className={classes.icon} />
          <h2 className={clsx('page__title', classes.title)}>Low Code</h2>
          <TbArrowsHorizontal className={classes.icon} />
          <h2 className={clsx('page__title', classes.title)}>No Code</h2>
        </div>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Choose the level of abstraction that works best or switch between them
          at any time.
        </p>
      </div>

      <div className={classes.visual}>
        <div className={clsx('page__background', classes.background)} />
        <div className={classes.wrapper}>
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx) and (min-width: 576px)'
              srcSet={CodeImageDesk2x.src}
            />
            <source media='(min-width: 576px)' srcSet={CodeImageDesk1x.src} />
            <source
              media='(min-resolution: 2dppx) and (max-width: 575px)'
              srcSet={CodeImageMob2x.src}
            />
            <source media='(max-width: 575px)' srcSet={CodeImageMob1x.src} />
            <img
              src={CodeImageMob1x.src}
              alt='Code / Low Code / No Code'
              loading='lazy'
            />
          </picture>
        </div>
      </div>
    </section>
  )
}
