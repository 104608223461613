const buildingBlocksArrayData = [
  {
    title: 'Data Sources',
    icon: 'data-sources',
    description: 'Read, write, and search data in external apps',
    href: 'https://console.integration.app/docs/engine/blueprints/data-sources',
  },
  {
    title: 'Data Events',
    icon: 'data-events',
    description: 'Know when data changes in external apps.',
    href: 'https://console.integration.app/docs/engine/blueprints/data-sources/data-source-events',
  },
  {
    title: 'Field Mappings',
    icon: 'field-mappings',
    description: 'Apply user-defined data transformations',
    href: 'https://console.integration.app/docs/engine/blueprints/field-mappings',
  },
  {
    title: 'Object Links',
    icon: 'object-links',
    description: 'Keep track of matching data objects',
    href: 'https://console.integration.app/docs/engine/blueprints/data-links',
  },
  {
    title: 'Custom Fields',
    icon: 'custom-fields',
    description: 'Deal with customer-specific application setups',
    href: 'https://console.integration.app/docs/engine/connectors/data/custom-fields',
  },
  {
    title: 'Monitoring',
    icon: 'monitoring',
    description: 'See when things break and why',
    href: 'https://console.integration.app/docs/engine/activity-log',
  },
  {
    title: 'API Logs',
    icon: 'api-logs',
    description: 'See what exactly happens inside your integratios',
    href: 'https://console.integration.app/docs/engine/activity-log',
  },
  {
    title: 'Unified Data Models',
    icon: 'data-models',
    description: 'Work with data in a consistent way',
    href: 'https://console.integration.app/docs/engine/references/udm',
  },
  {
    title: 'Data Schemas',
    icon: 'data-schemas',
    description: 'Use custom data structures in integrations',
    href: 'https://console.integration.app/docs/engine/blueprints/app-data-schemas',
  },
  {
    title: 'App Events',
    icon: 'app-events',
    description: 'Trigger integrations on events in your app',
    href: 'https://console.integration.app/docs/engine/blueprints/app-events',
  },
  {
    title: 'Flows',
    icon: 'flows',
    description: 'Multi-step integrations with branching and loops',
    href: 'https://console.integration.app/docs/engine/blueprints/flows',
  },
  {
    title: 'And more...',
    icon: 'add-more',
    description: 'This most extensive integration API on the market',
    href: 'https://console.integration.app/docs',
  },
]

const getPuzzleJigsXl = getPuzzeJigs(4, 'xl')
const getPuzzleJigsLg = getPuzzeJigs(3, 'lg')
const getPuzzleJigsMd = getPuzzeJigs(2, 'md')
const getPuzzleJigsSm = getPuzzeJigs(1, 'sm')

// Generate puzzle jigs for each building block
// The jigs are used to connect the building blocks and should not intersect with each other
export const buildingBlocksArray = pipe(
  buildingBlocksArrayData,
  getPuzzleJigsXl,
  getPuzzleJigsLg,
  getPuzzleJigsMd,
  getPuzzleJigsSm,
)

function pipe(arr, ...fns) {
  return fns.reduce((acc, curr) => curr(acc), arr)
}

function getPuzzeJigs(cols, key) {
  return function (arr) {
    return arr.reduce((acc, curr, index) => {
      const isFirstCol = index % cols === 0
      const isLastCol = index % cols === cols - 1
      const isFirstRow = index < cols
      const isLastRow = index >= arr.length - cols

      const jigsOnCellLeft = arr[index - 1]?.['puzzleJigs']?.[key] ?? []
      const jigOnCellAbove = arr[index - cols]?.['puzzleJigs']?.[key] ?? []

      const jigLocations = []

      // If the cell to the left does not have a jig on the right
      // then the cell must have a jig on the left to connect to the cell on the left
      if (!isFirstCol && !jigsOnCellLeft.includes('right')) {
        jigLocations.push('left')
      }

      // If the cell above does not have a jig on the bottom
      // then the cell must have a jig on the top to connect to the cell above
      if (!isFirstRow && !jigOnCellAbove.includes('bottom')) {
        jigLocations.push('top')
      }

      // Possible connections
      if (!isLastCol) {
        pushOrNot(jigLocations, 'right')
      }

      if (!isLastRow) {
        pushOrNot(jigLocations, 'bottom')
      }

      curr['puzzleJigs'] = curr['puzzleJigs'] ?? {}
      curr['puzzleJigs'][key] = jigLocations
      acc.push(curr)
      return acc
    }, [])
  }
}

function pushOrNot(array, item) {
  if (Math.random() > 0.5) {
    array.push(item)
  }
  return array
}
