import { useState } from 'react'
import { LINKS, DOCS_LINK } from 'routes/constants'
import { Link, Tabs } from 'routes/components/Shared'
import { ideData } from './ideData'
import { LandingIDECard } from './LandingIDECard'
import LogoIcon from './icons/icon-logo.svg'
import IDEMobImage1x from './images/landing-ide-mobile@1x.png'
import IDEMobImage2x from './images/landing-ide-mobile@2x.png'
import clsx from 'clsx'
import classes from './LandingIDE.module.scss'

export const LandingIDE = () => {
  const [activeId, setActiveId] = useState(ideData[1].id)
  const activeItem = ideData.find((item) => item.id === activeId)

  return (
    <section className={clsx('flex-column', classes.section)}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          The most powerful integration engine on the market
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          With 250+ API endpoints and comprehensive Ul to build, run, monitor,
          and troubleshoot integrations, our product is by far the most
          feature-rich and flexible on the market.
        </p>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          You can trust it to power your integrations for years to come.
        </p>
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.INTEGRATION_ENGINE}
        >
          Learn More
        </Link>

        <Link className={'page__button'} type='secondary' href={DOCS_LINK}>
          See Documentation
        </Link>
      </div>

      <div className={classes.visual}>
        <div className={(classes.wrapper, classes.wrapper__mobile)}>
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx)'
              srcSet={IDEMobImage2x.src}
            />
            <source srcSet={IDEMobImage1x.src} />
            <img src={IDEMobImage1x.src} alt='IDE picture' loading='lazy' />
          </picture>
        </div>

        <div className={clsx(classes.wrapper, classes.wrapper__desktop)}>
          <div className={clsx('page__background', classes.background)} />
          <div className={clsx('flex-column', classes.tabs)}>
            <>
              <nav className={clsx('flex-column', classes.tabs_nav)}>
                <div className={classes.tabs_logo}>
                  <img src={LogoIcon.src} alt='Integration.App Logo' />
                </div>
                <Tabs
                  className={classes.tabs_list}
                  tabsData={ideData}
                  activeId={activeId}
                  onTabClick={(tab) => setActiveId(tab)}
                />
              </nav>
              <LandingIDECard card={activeItem} />
            </>
          </div>
        </div>
      </div>
    </section>
  )
}
