import { StaticImageData } from 'next/image'
import UIIntegrationImage1x from './landing-ui-integration@1x.png'
import UIIntegrationImage2x from './landing-ui-integration@2x.png'
import UIDataModelsImage1x from './landing-ui-data-models@1x.png'
import UIDataModelsImage2x from './landing-ui-data-models@2x.png'

export type UIDataType = {
  title: string
  image1x: StaticImageData
  image2x: StaticImageData
}

export const uiData: UIDataType[] = [
  {
    title: 'Use pre-built UI components',
    image1x: UIIntegrationImage1x,
    image2x: UIIntegrationImage2x,
  },
  {
    title: 'Build fully custom UI',
    image1x: UIDataModelsImage1x,
    image2x: UIDataModelsImage2x,
  },
]
