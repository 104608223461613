export type HeroDataType = {
  action: string
  type: 'asymptotic' | 'linear'
  initialSpeed: number
  finalSpeed?: number
  description: string
}

export const heroData: HeroDataType[] = [
  {
    action: 'Integrate',
    type: 'asymptotic',
    initialSpeed: 3,
    finalSpeed: 0.01,
    description: 'different apps',
  },
  {
    action: 'Use',
    type: 'asymptotic',
    initialSpeed: 10,
    finalSpeed: 0.05,
    description: 'connections',
  },
  {
    action: 'Make',
    type: 'linear',
    initialSpeed: 2500,
    description: 'API requests',
  },
  {
    action: 'Access',
    type: 'asymptotic',
    initialSpeed: 7,
    finalSpeed: 0.7,
    description: 'data collections',
  },
  {
    action: 'Read',
    type: 'linear',
    initialSpeed: 500,
    description: 'data records',
  },
  {
    action: 'Write',
    type: 'linear',
    initialSpeed: 160,
    description: 'data records',
  },
  {
    action: 'Receive',
    type: 'linear',
    initialSpeed: 450,
    description: 'change events',
  },
  {
    action: 'Run',
    initialSpeed: 60,
    type: 'linear',
    description: 'integration flows',
  },
]
