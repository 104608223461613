import {
  LandingBadges,
  LandingBestSupport,
  LandingCases,
  LandingCode,
  LandingCompanies,
  LandingDevelopment,
  LandingHero,
  LandingIDE,
  LandingNetwork,
  LandingReviews,
  LandingIntegrations,
  LandingStructure,
  LandingUI,
  LandingVendorLock,
} from './components'
import { DefaultPageLayout } from 'routes/components/Layout'
import classes from './index.module.scss'

export default function IndexPage({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return (
    <DefaultPageLayout className={classes.page}>
      <div className='container'>
        <LandingHero />
        <LandingBadges />
        <LandingCompanies />
        <LandingCases />
        <LandingIDE />
        <LandingCode />
        <LandingNetwork totalIntegrations={totalIntegrations} />
        <LandingIntegrations />
        <LandingUI />
        <LandingDevelopment />
        <LandingStructure />
        <LandingVendorLock />
        <LandingBestSupport />
        <LandingReviews />
      </div>
    </DefaultPageLayout>
  )
}
