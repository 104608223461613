import { useEffect, useState } from 'react'

export const CounterValue = ({
  initialSpeed,
  finalSpeed,
}: {
  initialSpeed: number
  finalSpeed: number
}) => {
  const [counter, setCounter] = useState(0)

  const duration = 5 * 60 * 1000 // Fully slow down over 5 minutes
  const interval = 100 // Update interval in milliseconds

  useEffect(() => {
    const startTime = Date.now()

    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime
      const progress = Math.min(elapsedTime / duration, 1)
      const currentSpeed =
        finalSpeed + (initialSpeed - finalSpeed) * (1 - progress)

      const increment = currentSpeed * (interval / 1000)
      const randomizedIncrement =
        increment / 2 + (Math.random() * increment) / 2

      setCounter((counter) => counter + randomizedIncrement)
    }, interval)

    return () => clearInterval(intervalId)
  }, [initialSpeed, finalSpeed, interval])

  return <span>{Math.min(999999, Math.round(counter))}</span>
}
