import { CounterValue } from 'routes/Index/helpers'
import { heroData } from './heroData'
import type { HeroDataType } from './heroData'
import clsx from 'clsx'
import classes from './Counter.module.scss'

export function Counter({ className }: { className?: string }) {
  return (
    <div className={clsx(classes.data, className)}>
      <p className={clsx('page__subtitle', classes.subtitle)}>
        Since you opened this page, our engine was used to
      </p>
      <ul className={clsx('flex-column', classes.list)}>
        {heroData.map((item, index) => (
          <CounterCard key={index} item={item} />
        ))}
      </ul>
    </div>
  )
}

const CounterCard = ({ item }: { item: HeroDataType }) => {
  const { action, initialSpeed, finalSpeed, description } = item

  return (
    <li className={classes.item}>
      <p className={clsx(classes.item_text, classes.item_text__action)}>
        {action}
      </p>
      <div className={classes.item_wrapper}>
        <div className={classes.item_number}>
          <span className={classes.item_number__base}>000000</span>
          <span className={classes.item_number__main}>
            <CounterValue
              initialSpeed={initialSpeed}
              finalSpeed={finalSpeed ?? initialSpeed}
            />
          </span>
        </div>
      </div>
      <p className={classes.item_text}>{description}</p>
    </li>
  )
}
