import DevelopmentMobImage1x from './landing-development-mob@1x.png'
import DevelopmentMobImage2x from './landing-development-mob@2x.png'
import DevelopmentDeskImage1x from './landing-development-desk@1x.png'
import DevelopmentDeskImage2x from './landing-development-desk@2x.png'
import clsx from 'clsx'
import classes from './LandingDevelopment.module.scss'

export const LandingDevelopment = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.body)}>
        <h2 className={clsx('page__title', classes.title)}>
          Keep integrations a part of your development process
        </h2>

        <p className={clsx('page__subtitle', classes.subtitle)}>
          Our integration engine seamlessly fits into your existing development
          infrastructure an processes.
        </p>

        <div className={classes.visual}>
          <div className={clsx('page__background', classes.background)} />
          <div className={classes.image}>
            <div className={clsx('page__glow', classes.glow)} />
            <picture>
              <source
                media='(min-resolution: 2dppx) and (min-width: 576px)'
                srcSet={DevelopmentDeskImage2x.src}
              />
              <source
                media='(min-width: 576px)'
                srcSet={DevelopmentDeskImage1x.src}
              />
              <source
                media='(min-resolution: 2dppx) and (max-width: 575px)'
                srcSet={DevelopmentMobImage2x.src}
              />
              <source
                media='(max-width: 575px)'
                srcSet={DevelopmentMobImage1x.src}
              />
              <img
                src={DevelopmentMobImage1x.src}
                alt='Integration Engine'
                loading='lazy'
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
