import ScaleImageMob1x from './landing-scale-mob@1x.png'
import ScaleImageMob2x from './landing-scale-mob@2x.png'
import ScaleImageDesk1x from './landing-scale-desk@1x.png'
import ScaleImageDesk2x from './landing-scale-desk@2x.png'
import clsx from 'clsx'
import classes from './IntegrationsVisual.module.scss'

interface LandingScaleVisualProps {
  bgIsVisible?: boolean
}

export const LandingIntegrationsVisual = ({
  bgIsVisible,
}: LandingScaleVisualProps) => {
  return (
    <div className={classes.visual}>
      {bgIsVisible && (
        <div className={clsx('page__background', classes.background)} />
      )}
      <div className={classes.wrapper}>
        <picture className={classes.image}>
          <source
            media='(min-resolution: 2dppx) and (min-width: 576px)'
            srcSet={ScaleImageDesk2x.src}
          />
          <source media='(min-width: 576px)' srcSet={ScaleImageDesk1x.src} />
          <source
            media='(min-resolution: 2dppx) and (max-width: 575px)'
            srcSet={ScaleImageMob2x.src}
          />
          <source media='(max-width: 575px)' srcSet={ScaleImageMob1x.src} />
          <img
            src={ScaleImageMob1x.src}
            alt='Scale your integration scenarios'
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
