import IconSupermanSign from './icon-sign.svg'
import IconWonderwoman from './icon-wonderwoman.svg'
import IconSuperman from './icon-superman.svg'
import clsx from 'clsx'
import classes from './LandingBestSupport.module.scss'

export const LandingBestSupport = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <div className={classes.header}>
        <img
          src={IconSupermanSign.src}
          alt='Superman Sign Icon'
          className={clsx(classes.icon, classes.icon__sign)}
        />
      </div>
      <div className={classes.content}>
        <div className={clsx('flex-column', classes.heading)}>
          <h2 className={clsx('page__title', classes.title)}>
            Best support and services on the market.
            <br />
            Period.
          </h2>
          <p className={clsx('page__subtitle', classes.subtitle)}>
            Our world-class solutions team is just one Slack message away. We
            help you understand complex APIs, build robust integration logic,
            and keep your customer integrations running smoothly
          </p>
        </div>
        <div className={clsx(classes.visual, classes.visual__woman)}>
          <div className={clsx('page__background', classes.background)} />
          <img
            src={IconWonderwoman.src}
            alt='Wonderwoman Icon'
            className={clsx(classes.icon, classes.icon__woman)}
          />
        </div>
        <div className={clsx(classes.visual, classes.visual__man)}>
          <div className={clsx('page__background', classes.background)} />
          <img
            src={IconSuperman.src}
            alt='Superman Icon'
            className={clsx(classes.icon, classes.icon__man)}
          />
        </div>
      </div>
    </section>
  )
}
