// import { Link } from 'routes/components/Shared'
import { casesData } from './casesData'
import { LandingCasesCard } from './LandingCasesCard'
import clsx from 'clsx'
import classes from './LandingCases.module.scss'

export const LandingCases = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.body)}>
        <h2 className={clsx('page__title', classes.title)}>
          Ship integration features of any complexity 10x faster
        </h2>

        <ul className={clsx('flex-column', classes.list)}>
          {casesData.map((item, index) => (
            <LandingCasesCard key={index} item={item} />
          ))}
        </ul>

        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href='#'
        >
          Explore Solutions
        </Link> */}
      </div>
    </section>
  )
}
